import React, { useState } from "react";
import { Input, FormLabel, Button, Box, Textarea } from "@mui/joy";
import { Formik, Form, FieldAttributes, useField } from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  openPrivacyPolicy: () => void;
}

const fieldCommonStyles = {
  color: "common.white",
  paddingTop: 3,
};

const MyTextInput = ({ label, ...props }: FieldAttributes<any>) => {
  const [field, meta] = useField(props);
  return (
    <>
      <FormLabel htmlFor={props.id || props.name} sx={fieldCommonStyles}>
        {label}
      </FormLabel>
      <Input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div
          className="error"
          style={{ color: "#F2695C", position: "absolute" }}
        >
          {meta.error}
        </div>
      ) : null}
    </>
  );
};

const MyTextarea = ({ label, ...props }: FieldAttributes<any>) => {
  const [field, meta] = useField(props);
  return (
    <>
      <FormLabel htmlFor={props.id || props.name} sx={fieldCommonStyles}>
        {label}
      </FormLabel>
      <Textarea className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div
          className="error"
          style={{ color: "#F2695C", position: "absolute" }}
        >
          {meta.error}
        </div>
      ) : null}
    </>
  );
};

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  workType: Yup.string().required("Type of Work is required"),
  message: Yup.string().required("Message is required"),
});

const InquiryModal: React.FC<Props> = ({
  isOpen,
  onClose,
  openPrivacyPolicy,
}) => {
  const [isSent, setIsSent] = useState(false);
  const [isAnimatingOut, setAnimatingOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [endMessage, setEndMessage] = useState("");

  const modalVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const startCloseAnimation = () => {
    setAnimatingOut(true);
  };

  const handleSubmit = async (values: any) => {
    let response;
    setIsLoading(true);
    try {
      response = await fetch(
        "https://kge0l78k5a.execute-api.us-east-1.amazonaws.com/OuterWorldsLaunchStage",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values),
        },
      );

      if (response.status === 200) {
        setIsSent(true);
        setEndMessage(
          "Thank you! We will get back to you as soon as possible.",
        );
      } else {
        setIsSent(true);
        setEndMessage(
          "Ops! Something went wrong and we are beating the engineers. Please refresh and try again later.",
        );
      }
    } catch (error) {
      setIsSent(true);
      setEndMessage(
        "Ops! Something went wrong and we are beating the engineers. Please refresh and try again later.",
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen && !isAnimatingOut) return null;

  return (
    <motion.div
      initial="hidden"
      animate={isAnimatingOut ? "exit" : "visible"}
      variants={modalVariants}
      onAnimationComplete={(currentAnimationState) => {
        if (currentAnimationState === "exit") {
          onClose();
          setAnimatingOut(false);
        }
      }}
      style={{ background: "rgba(0, 0, 0, 0.7)" }}
    >
      <div
        style={{
          position: "fixed",
          color: "white",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0,0,0,0.7)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 10,
        }}
      >
        {isSent ? (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={modalVariants}
          >
            <Box
              component="div"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                maxWidth: 450,
                height: "fit-content",
                bgcolor: "rgba(0, 0, 0, 0.5)",
                p: 4,
                textAlign: "center",
              }}
            >
              {endMessage}
            </Box>
          </motion.div>
        ) : (
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              workType: "",
              message: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <MyTextInput
                name="firstName"
                label="First Name"
                type="text"
                placeholder="Jane"
              />
              <MyTextInput
                name="lastName"
                label="Last Name"
                type="text"
                placeholder="Doe"
              />
              <MyTextInput
                name="email"
                label="Email"
                type="email"
                placeholder="Jane@email.com"
              />
              <MyTextInput
                name="workType"
                label="Type of Work"
                type="text"
                placeholder="Website..."
              />
              <MyTextarea
                name="message"
                label="Message"
                minRows={3}
                placeholder="Send us a message!"
              />
              <br />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                  paddingTop: 3,
                }}
              >
                <Button
                  fullWidth
                  type="button"
                  color="danger"
                  onClick={startCloseAnimation}
                >
                  Abort!
                </Button>
                <Button
                  loading={isLoading}
                  loadingPosition="end"
                  fullWidth
                  type="submit"
                >
                  Send it!
                </Button>
              </Box>
            </Form>
          </Formik>
        )}
        <Button onClick={openPrivacyPolicy} variant="plain">
          Privacy Policy
        </Button>
      </div>
    </motion.div>
  );
};

export default InquiryModal;
