import React from "react";
import "./PrivacyPolicyModel.css";
import { Button } from "@mui/joy";

interface PrivacyPolicyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" style={{ zIndex: 1}}>
      <div className="modal">
        <Button
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            m: 2,
            zIndex: 1,
          }}
          variant="plain"
          onClick={onClose}
        >
          Close
        </Button>
        <h2>Privacy Policy for OuterWorlds Creative</h2>
        <p>
          This privacy policy outlines how OuterWorlds Creative collects, uses,
          maintains, and discloses information collected from users (each, a
          "User") of the www.outerworldscreative.com website ("Site").
        </p>
        <div>
          <h3>Personal Identification Information</h3>
          <p>
            We may collect personal identification information from Users in a
            variety of ways, including, but not limited to, when Users visit our
            site, register on the site, place an order, subscribe to the
            newsletter, respond to a survey, fill out a form, and in connection
            with other activities, services, features or resources we make
            available on our Site. Users may be asked for, as appropriate, name,
            email address, mailing address, phone number, and credit card
            information. We will collect personal identification information
            from Users only if they voluntarily submit such information to us.
            Users can always refuse to supply personally identification
            information, except that it may prevent them from engaging in
            certain Site related activities.{" "}
          </p>

          <h3>Personal Identification Information</h3>
          <p>
            We may collect personal identification information from Users in a
            variety of ways, including, but not limited to, when Users visit our
            site, register on the site, place an order, subscribe to the
            newsletter, respond to a survey, fill out a form, and in connection
            with other activities, services, features or resources we make
            available on our Site. Users may be asked for, as appropriate, name,
            email address, mailing address, phone number, and credit card
            information. We will collect personal identification information
            from Users only if they voluntarily submit such information to us.
            Users can always refuse to supply personally identification
            information, except that it may prevent them from engaging in
            certain Site related activities.{" "}
          </p>
          <h3>Non-Personal Identification Information</h3>
          <p>
            We may collect non-personal identification information about Users
            whenever they interact with our Site. Non-personal identification
            information may include the browser name, the type of computer and
            technical information about Users' means of connection to our Site,
            such as the operating system and the Internet service providers
            utilized and other similar information.
          </p>
          <h3>Web Browser Cookies</h3>
          <p>
            Our Site may use "cookies" to enhance User experience. User's web
            browser places cookies on their hard drive for record-keeping
            purposes and sometimes to track information about them. Users may
            choose to set their web browser to refuse cookies, or to alert you
            when cookies are being sent. If they do so, note that some parts of
            the Site may not function properly.
          </p>
          <h3>How We Use Collected Information</h3>
          <p>
            OuterWorlds Creative may collect and use Users personal information
            for the following purposes:
          </p>
          <ul>
            <li>
              To process payments: We may use the information Users provide
              about themselves when placing an order only to provide service to
              that order. We do not share this information with outside parties
              except to the extent necessary to provide the service.
            </li>
            <li>
              To send periodic emails: We may use the email address to send User
              information and updates pertaining to their order. It may also be
              used to respond to their inquiries, questions, and/or other
              requests
            </li>
          </ul>
          <h3>How We Protect Your Information</h3>
          <p>
            We adopt appropriate data collection, storage and processing
            practices and security measures to protect against unauthorized
            access, alteration, disclosure or destruction of your personal
            information stored on our Site.
          </p>
          <h3>Usage of Information</h3>
          <p>
            The information we collect from our Users serves a variety of
            purposes. Firstly, it facilitates the provision of services
            requested by the Users, including handling orders and providing
            access to exclusive content. Secondly, it enables us to personalize
            the user experience, as understanding how our Users as a group use
            the services and resources provided on our Site helps us to refine
            and cater our product offerings and business strategies. Information
            like email addresses may be used to send Users information and
            updates pertaining to their order, in addition to receiving
            occasional company news, updates, related product or service
            information, etc. Users can opt-out of this at any time. Finally, we
            use feedback provided to improve our services and products. We
            continually strive to improve our website offerings based on the
            information and feedback we receive from our Users.{" "}
          </p>
          <h3>Security of Information</h3>
          <p>
            We adopt appropriate data collection, storage and processing
            practices and security measures to protect against unauthorized
            access, alteration, disclosure or destruction of your personal
            information, username, password, transaction information and data
            stored on our Site.
          </p>
          <p>
            Sensitive and private data exchange between the Site and its Users
            happens over a SSL secured communication channel and is encrypted
            and protected with digital signatures. Our Site is in compliance
            with PCI vulnerability standards in order to create as secure of an
            environment as possible for Users.
          </p>
          <h3>Sharing Your Information</h3>
          <p>
            We do not sell, trade, or rent Users personal identification
            information to others. We may share generic aggregated demographic
            information not linked to any personal identification information
            regarding visitors and users with our business partners, trusted
            affiliates and advertisers for the purposes outlined above.
          </p>
          <h3>Changes to this Privacy Policy</h3>
          <p>
            OuterWorlds Creative has the discretion to update this privacy
            policy at any time. When we do, we will revise the updated date at
            the bottom of this page. We encourage Users to frequently check this
            page for any changes to stay informed about how we are helping to
            protect the personal information we collect. You acknowledge and
            agree that it is your responsibility to review this privacy policy
            periodically and become aware of modifications.
          </p>
          <h3>Your Acceptance of these Terms</h3>
          <p>
            By using this Site, you signify your acceptance of this policy. If
            you do not agree to this policy, please do not use our services.
            Your continued use of the Site following the posting of changes to
            this policy will be deemed your acceptance of those changes.
          </p>
          <h3>Contacting Us</h3>
          <p>
            If you have any questions about this Privacy Policy, the practices
            of this site, or your dealings with this site, please contact us at:
          </p>
          <p>OuterWorlds Creative</p>
          <p>www.outerworldscreative.com</p>
          <p>kyle@outerworldcreative.com</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyModal;
