import React from "react";
import { Box, Button } from "@mui/joy";

interface Props {
  openPrivacyPolicy: () => void;
}

const Footer: React.FC<Props> = ({ openPrivacyPolicy }) => {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      component="footer"
      sx={{
        background: "linear-gradient(90deg, #1E2053, #F68361, #78537E)",
        width: "100%",
        height: "150px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "common.white",
        fontSize: "0.8rem",
        minHeight: 150,
        boxSizing: "border-box",
        flexDirection: "column",
      }}
    >
      <Button sx={{ zIndex: 0 }} onClick={openPrivacyPolicy} variant="plain">Privacy Policy</Button>
      <Box
        component="div"
        sx={{
          maxWidth: 1080,
          justifyContent: "center",
          width: "100%",
          boxSizing: "border-box",
          padding: 2,
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        &copy; {currentYear} OuterWorlds Creative. All rights reserved.
      </Box>
    </Box>
  );
};

export default Footer;
