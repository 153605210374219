import * as React from "react";
import landscape from "../../images/compressed_space_landscape_background_cartoon_fantasy_alien.png";
import { Typography } from "@mui/joy";
import Button from "@mui/joy/Button";
import { Box } from "@mui/system";
import { useState } from "react";
import InquiryModal from "../../components/inquiry-model/InquiryModel";
import PrivacyPolicyModal from "../../components/privacy-policy-model/PrivacyPolicyModel";

export const TransparentBox: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Box
    component="div"
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: 1,
      maxWidth: 450,
      height: "fit-content",
      bgcolor: "rgba(0, 0, 0, 0.5)",
      p: 2,
      justifySelf: "flex-end",
      alignSelf: "flex-end",
    }}
  >
    {children}
  </Box>
);

const HeroBox: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Box
    component="div"
    sx={{
      width: "100vw",
      height: "100vh",
      minHeight: 500,
      boxSizing: "border-box",
      backgroundImage: `url(${landscape})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center bottom",
      p: {
        xs: 2, // Smaller padding on small screens
        sm: 8, // Slightly larger padding on slightly larger screens
      },
    }}
  >
    {children}
  </Box>
);

interface Props {
  isPrivacyPolicyOpen: boolean;
  openPrivacyPolicy: () => void;
  closePrivacyPolicy: () => void;
}

const LandingPage: React.FC<Props> = ({
  isPrivacyPolicyOpen,
  openPrivacyPolicy,
  closePrivacyPolicy,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <HeroBox>
        <Box
          component="section"
          sx={{
            display: "grid",
            maxWidth: 1080,
            flexDirection: "column",
            margin: "0 auto",
            height: "50%",
          }}
        >
          <Typography
            level="h1"
            fontSize={48}
            letterSpacing={0.5}
            textColor="#F29D52"
          >
            OuterWorlds Creative
          </Typography>
          {isModalOpen ? null : (
            <TransparentBox>
              <Typography level="title-lg" textColor="common.white">
                Crafting Digital Experiences Beyond Boundaries.
              </Typography>
              <Typography level="body-lg" textColor="#c599a9">
                Dive into a universe of innovative webworks and software
                solutions.
              </Typography>
              <Button
                onClick={() => setIsModalOpen(true)}
                variant="solid"
                sx={{ width: "fit-content", alignSelf: "flex-end" }}
              >
                Inquire Our Services
              </Button>
            </TransparentBox>
          )}
        </Box>
        <InquiryModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          openPrivacyPolicy={openPrivacyPolicy}
        />
        <PrivacyPolicyModal
          isOpen={isPrivacyPolicyOpen}
          onClose={closePrivacyPolicy}
        />
      </HeroBox>
    </>
  );
};

export default LandingPage;
