import React, { useState } from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import Sheet from "@mui/joy/Sheet";
import LandingPage from "./pages/landing-page/LandingPage";
import "@fontsource/inter";
import Footer from "./components/footer/Footer";

function App() {
  const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);

  const openPrivacyPolicy = () => setPrivacyPolicyOpen(true);
  const closePrivacyPolicy = () => setPrivacyPolicyOpen(false);

  return (
    <CssVarsProvider>
      <Sheet variant="plain">
        <LandingPage
          isPrivacyPolicyOpen={isPrivacyPolicyOpen}
          openPrivacyPolicy={openPrivacyPolicy}
          closePrivacyPolicy={closePrivacyPolicy}
        />
        <Footer openPrivacyPolicy={openPrivacyPolicy} />
      </Sheet>
    </CssVarsProvider>
  );
}

export default App;
